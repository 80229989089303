import { HorseActivity, SimpleHorse } from "@/openapi";
import {
  activityTypeColor,
  activityTypeIcon,
  activityTypeToString,
} from "@/utils/stringUtils";
import { format } from "date-fns"; // Import date-fns for formatting dates
import { useRouter } from "next/router";
import HorseAvatar from "../../misc/HorseAvatar";
import clsx from "clsx";
import Image from "next/image";
import { _ } from "@/pages/_app";
import ActivityLogTrackTraining from "./activityLogTrackTraining";
import ActivityLogCustomTraining from "./activityLogCustomTraining";
import ColumnHorses from "@/components/horse/ColumnHorses";
import LikeCommentShareRow from "@/components/buttons/LikeCommentShareRow";
import { dateToReadable } from "@/utils/dateUtils";
import ActivityLogTrackTrainingBottom from "./activityLogTrackTrainingBottom";

interface ActivityLogCardProps {
  activity: HorseActivity;
  big?: boolean;
  noShadow?: boolean;
}

const ActivityLogCard = ({ activity, ...props }: ActivityLogCardProps) => {
  const router = useRouter();

  const renderUser = () => {
    return (
      <>
        {activity.actor ? (
          <div
            className="flex flex-row mt-0"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              router.push("/riders/" + activity.actor?.uid);
            }}
          >
            {/* <UserAvatar user={activity.actor} radius={24} /> */}
            <span className=" text-sm ml-0">
              {activity.actor?.name ?? "no user"}
            </span>
          </div>
        ) : (
          <div>No actor</div>
        )}
      </>
    );
  };

  const renderActivityTypeInfo = () => {
    switch (activity.type) {
      case "TRAINING_SESSION":
        return <ActivityLogTrackTraining activity={activity} />;
      case "CUSTOM_TRAINING_SESSION":
        return <ActivityLogCustomTraining activity={activity} />;
      default:
        return <></>;
    }
  };

  const renderBottomActivityInfoByType = () => {
    switch (activity.type) {
      case "TRAINING_SESSION":
        return <ActivityLogTrackTrainingBottom activity={activity} />;

      default:
        return <></>;
    }
  };

  const renderTaggedHorses = () => {
    let taggedHorses: SimpleHorse[] = [];
    switch (activity.type) {
      case "MEDICAL":
        taggedHorses = activity.medical?.taggedHorses ?? [];
      case "SHOEING":
        taggedHorses = activity.shoeing?.taggedHorses ?? [];
      case "CUSTOM_TRAINING_SESSION":
        taggedHorses = activity.customTrainingSession?.taggedHorses ?? [];
      case "TRAINING_SESSION":
        taggedHorses = activity.trainingSession?.taggedHorses ?? [];
    }

    if (taggedHorses.length === 0) {
      return <></>;
    }

    return (
      <div>
        <ColumnHorses
          horses={taggedHorses}
          numColumns={2}
          title={_("Tagged horses")}
        />
      </div>
    );
  };

  return (
    <div
      style={{ minWidth: 400 }}
      className={clsx(
        "bg-white rounded-sm p-4 mb-4 mt-4 ",
        props.big ? "" : "cursor-pointer",
        props.noShadow ? "" : "shadow-md"
      )}
      onClick={() => {
        if (!props.big) {
          router.push("/activity/" + activity.id);
        }
      }}
    >
      <div className="flex flex-row justify-between">
        <div className="flex flex-row">
          <div className="mr-2 pt-1">{activityTypeIcon(activity.type)}</div>
          <h2
            className={clsx("text-md")}
            style={{ color: activityTypeColor(activity.type) }}
          >
            {activityTypeToString(activity.type)}
          </h2>
        </div>
        {activity.isDraft && "Draft"}
        {/* <p className="text-gray-500 text-sm">
          {activity.dtPerformed &&
            format(new Date(activity.dtPerformed), "dd.MM.yyyy | HH:mm")}
        </p> */}
      </div>
      <hr className="m-2" />

      {activity.horse ? (
        <div
          className="flex flex-row cursor-pointer"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            router.push("/horses/" + activity.horse?.faedingarnumer);
          }}
        >
          <HorseAvatar simpleHorse={activity.horse} />
          <div className="flex flex-col items-start justify-start ml-4">
            <span className="font-medium text-md ">
              {activity.horse?.name ?? "no horse"}
            </span>
            {renderUser()}
            <p className="text-gray-500 text-sm">
              {activity.dtPerformed &&
                dateToReadable(new Date(activity.dtPerformed))}
            </p>
          </div>
        </div>
      ) : (
        <div>
          No horse
          <div className="flex flex-col items-start justify-start ml-4">
            {renderUser()}
            <p className="text-gray-500 text-sm">
              {activity.dtPerformed &&
                dateToReadable(new Date(activity.dtPerformed))}
            </p>
          </div>
        </div>
      )}

      {renderActivityTypeInfo()}

      {activity.text && <p className="mt-2">{activity.text}</p>}
      {activity.photos.length > 0 && (
        <div className="mt-2 flex flex-row overflow-x-scroll cursor-pointer">
          {activity.photos.map((photo) => (
            <Image
              onClick={(e) => {
                e.preventDefault();
                window.open(photo.image);
              }}
              width={props.big ? 600 : 300}
              height={props.big ? 600 : 300}
              key={photo.id}
              src={photo.image ?? ""}
              alt={`Photo of ${activity.type}`}
              className=" object-cover rounded-md shadow-sm mr-2"
            />
          ))}
        </div>
      )}
      {activity.videos.length > 0 && (
        <div className="mt-2">
          {activity.videos.map((video: any) => (
            <video
              key={video.id}
              src={video.url}
              className="w-20 h-20 object-cover rounded-md shadow-sm mr-2"
              controls
            />
          ))}
        </div>
      )}
      {renderBottomActivityInfoByType()}
      {renderTaggedHorses()}
      <LikeCommentShareRow big={props.big} activity={activity} />
    </div>
  );
};

export default ActivityLogCard;
