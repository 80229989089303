import {
  HorseActivity,
  Comment,
  CommentRequest,
  ActivityReactionRequest,
} from "@/openapi";
import { _ } from "@/pages/_app";
import store from "@/redux/store";
import { lightBrown } from "@/utils/colors";
import { captureException, devPrint } from "@/utils/devUtils";
import { navigateToUserProfile } from "@/utils/navigateUtils";
import {
  activitiesApi,
  commentsApi,
  userActivityReactionsApi,
} from "@/utils/NetworkUtils";
import { hasLikedActivity, publicUserToSimpleUser } from "@/utils/userUtils";
import { format } from "date-fns";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import CommentIcon from "../icons/CommentIcon";
import LikeIcon from "../icons/LikeIcon";
import ShareIcon from "../icons/ShareIcon";
import TextInput from "../inputs/TextInput";
import UserAvatar from "../user/userAvatar";
import { dateToReadable } from "@/utils/dateUtils";
import { toast } from "react-toastify";

interface ILikeCommentShareRow {
  activity: HorseActivity;
  big?: boolean;
}

const LikeCommentShareRow = ({ activity, ...props }: ILikeCommentShareRow) => {
  const [comments, setComments] = useState([] as Comment[]);
  const [text, setText] = useState("");
  const [hasLiked, setHasLiked] = useState(hasLikedActivity(activity.id));
  const [numLikes, setNumLikes] = useState(activity.reactionSummary.LIKE ?? 0);
  const [numComments, setNumComments] = useState(activity.commentCount);
  const router = useRouter();
  useEffect(() => {
    if (comments.length == 0) {
      commentsApi.commentsList(activity.id).then((e) => {
        if (e?.results) {
          setComments(e.results);
          setHasLiked(hasLikedActivity(activity.id));
        }
      });
    }
  }, [activity]);

  const renderComments = () => {
    return (
      <div>
        {comments.map((e) => {
          return (
            <div
              className="flex flex-row items-center mt-4 "
              key={"comment" + activity.id + e.id}
            >
              <div
                className="cursor-pointer"
                onClick={() => {
                  navigateToUserProfile(router, e.user);
                }}
              >
                <UserAvatar user={e.user} />{" "}
              </div>
              <div
                style={{}}
                className="ml-4 bg-lightest-gray rounded-xl  p-4 text-sm"
              >
                <span
                  onClick={() => {
                    navigateToUserProfile(router, e.user);
                  }}
                  className="font-bold cursor-pointer"
                >
                  {e.user.name}
                </span>
                <br />
                <span>{e.text}</span>
                <br />
                <span
                  style={{ fontSize: 12 }}
                  className="text-right text-stone-gray"
                >
                  {dateToReadable(e.dtCreated)}
                </span>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const handleSendComment = async () => {
    try {
      var comment = {
        id: numComments,
        text: text,
        activity: activity.id,
        dtCreated: new Date(),
        user: publicUserToSimpleUser(store.getState().userState.user),
      } as Comment;
      setComments(comments.concat(comment));
      setNumComments(numComments + 1);
      setText("");
      var res = await commentsApi.commentsCreate(comment);
    } catch (e) {
      captureException(e);
    }
  };

  const renderCommentInput = () => {
    return (
      <TextInput
        value={text}
        placeholder={_("Write a comment...")}
        className="mt-4"
        onEnter={() => {
          handleSendComment();
        }}
        onChange={(e) => {
          setText(e);
        }}
      />
    );
  };

  const handleLike = async () => {
    setHasLiked(!hasLiked);
    if (hasLiked) {
      setNumLikes(numLikes - 1);
    } else {
      setNumLikes(numLikes + 1);
    }

    try {
      if (!hasLiked) {
        var res = await userActivityReactionsApi.userActivityReactionsCreate({
          activity: activity.id,
          type: "LIKE",
        } as ActivityReactionRequest);
      } else {
        toast.error("unlike not supported");
      }
    } catch (e) {
      captureException(e);
    }
  };

  const topRow = (
    <div className="flex flex-row justify-between text-sm text-stone-gray pt-4">
      <div
        className="w-full flex flex-row items-center justify-center cursor-pointer"
        onClick={(e) => {
          e.preventDefault();
          handleLike();
        }}
      >
        <LikeIcon fill={hasLiked ? lightBrown : "none"} height={18} />{" "}
        {_("Like")}
      </div>
      <div className="w-full flex flex-row items-center justify-center cursor-pointer">
        <CommentIcon height={18} /> {_("Comment")}
      </div>
      <div className="w-full flex flex-row items-center justify-center cursor-pointer">
        <ShareIcon height={18} /> {_("Share")}
      </div>
    </div>
  );

  const renderNumLikes = () => {
    return (
      <div
        style={{ fontSize: 10 }}
        className="mt-4 flex flex-row justify-between text-sm text-stone-gray"
      >
        <span>{numLikes} Likes</span>
        <span>{numComments} Comments</span>
      </div>
    );
  };

  return (
    <div>
      {renderNumLikes()}
      <hr className="mt-1" />
      {topRow}
      <hr className="mt-4" />
      {props.big && renderCommentInput()}
      {renderComments()}
    </div>
  );
};

export default LikeCommentShareRow;
