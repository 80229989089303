import GallopIcon from "@/components/icons/GallopIcon";
import PaceIcon from "@/components/icons/PaceIcon";
import ToltIcon from "@/components/icons/ToltIcon";
import TrotIcon from "@/components/icons/TrotIcon";
import WalkIcon from "@/components/icons/WalkIcon";
import { HorseActivity } from "@/openapi";
import { _ } from "@/pages/_app";
import { distanceToReadable } from "@/utils/stringUtils";
import { Default } from "react-toastify/dist/utils";

interface IGaitAnalysisComponent {
  activity: HorseActivity;
}

const GaitAnalysisComponent = (props: IGaitAnalysisComponent) => {
  if (
    props.activity.trainingSession?.trainingSummary?.walkDistance === undefined
  ) {
    return <></>;
  }

  var summary = props.activity.trainingSession.trainingSummary;

  var totalLength = summary.distance ?? 1;

  var items = [
    {
      icon: <WalkIcon className="block m-auto" />,
      label: "walk",
      value: summary.walkDistance ?? 0,
      ratio: (((summary.walkDistance ?? 0) / totalLength) * 100).toFixed(0),
    },
    {
      icon: <ToltIcon className="block m-auto" />,
      label: "tolt",
      value: summary.toltDistance ?? 0,
      ratio: (((summary.toltDistance ?? 0) / totalLength) * 100).toFixed(0),
    },
    {
      icon: <TrotIcon className="block m-auto" />,
      label: "trot",
      value: summary.trotDistance ?? 0,
      ratio: (((summary.trotDistance ?? 0) / totalLength) * 100).toFixed(0),
    },
    {
      icon: <GallopIcon className="block m-auto" />,
      label: "gallop",
      value: summary.gallopDistance ?? 0,
      ratio: (((summary.gallopDistance ?? 0) / totalLength) * 100).toFixed(0),
    },
    {
      icon: <PaceIcon className="block m-auto" />,
      label: "pace",
      value: summary.paceDistance ?? 0,
      ratio: (((summary.paceDistance ?? 0) / totalLength) * 100).toFixed(0),
    },
  ];

  return (
    <div className="flex flex-row justify-around pt-4">
      {items.map((e) => {
        return (
          <div key={e.label} className="text-center">
            {e.icon}
            <div className="text-sm font-bold">{_(e.label)}</div>
            <div>{distanceToReadable(e.value)}</div>
            <div>{e.ratio} %</div>
          </div>
        );
      })}
    </div>
  );
};

export default GaitAnalysisComponent;
