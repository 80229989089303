import { Horse, SimpleHorse, SimpleUser } from "@/openapi";
import { NextRouter } from "next/router";

export const navigateToUserProfile = (
  router: NextRouter,
  user?: SimpleUser
) => {
  if (!user) {
    return;
  }
  const url = "/riders/" + user.uid;
  router.push(url);
};

export const navigateToHorse = (
  router: NextRouter,
  horse?: Horse | SimpleHorse
) => {
  if (!horse) {
    return;
  }
  const url = "/horses/" + horse.faedingarnumer;
  router.push(url);
};

export const navigateToActivity = (router: NextRouter, activityId?: number) => {
  if (!activityId) {
    return;
  }
  const url = "/activity/" + activityId;
  router.push(url);
};

export const navigateToSalesHorse = (
  router: NextRouter,
  horse?: Horse | SimpleHorse
) => {
  if (!horse) {
    return;
  }
  const url = "/horses/sale/" + horse.faedingarnumer;
  router.push(url);
};
