import { _ } from "@/pages/_app";
import {
  distanceToReadable,
  speedToReadable,
  timeToReadable,
} from "@/utils/stringUtils";

interface ITimeDistanceWidget {
  time?: number;
  distance?: number;
}

const TimeDistanceWidget = (props: ITimeDistanceWidget) => {
  const speed =
    props.time && props.distance ? props.distance / props.time : null;

  if (!props.distance && !props.time) {
    return <></>;
  }
  return (
    <div className="flex flex-row justify-between w-80 m-auto text-xs">
      {props.time && props.time !== 0 && (
        <div className="text-center">
          <div className="font-bold">{_("Time")}</div>
          <div>{timeToReadable(props.time)}</div>
        </div>
      )}
      {props.distance && (
        <div className="text-center">
          <div className="font-bold">{_("Distance")}</div>
          <div>{distanceToReadable(props.distance)}</div>
        </div>
      )}
      {speed && speed !== 0 && (
        <div className=" text-center">
          <div className="font-bold">{_("Speed")}</div>
          <div>{speedToReadable(props.distance, props.time)}</div>
        </div>
      )}
    </div>
  );
};

export default TimeDistanceWidget;
