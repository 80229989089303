import TimeDistanceWidget from "@/components/training/timeDistanceWidget";
import { HorseActivity } from "@/openapi";
import { _ } from "@/pages/_app";
import AnyTypesWidget from "./anyTypesWidget";

interface IActivityLogCustomTraining {
  activity: HorseActivity;
}

const ActivityLogCustomTraining = (props: IActivityLogCustomTraining) => {
  const session = props.activity.customTrainingSession;

  if (!session) {
    return <></>;
  }

  return (
    <div className="mt-4">
      <AnyTypesWidget
        types={session.trainingTypes?.split("/n")}
        title={_("Training Type")}
      />
      <TimeDistanceWidget time={session.duration} distance={session.distance} />
    </div>
  );
};

export default ActivityLogCustomTraining;
