import { HorsedayIconProps } from "./IconProps";

const LikeIcon = (props: HorsedayIconProps) => {
  const stroke: string = props.color ?? "#332723";
  return (
    <svg
      width={props.width ?? "24"}
      height={props.height ?? "24"}
      viewBox="0 0 24 24"
      fill={props.fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_231_3937)">
        <path
          d="M15.364 6.66694L16.049 4.98594C16.1165 4.82041 16.216 4.6698 16.3417 4.5427C16.4674 4.4156 16.6169 4.3145 16.7817 4.24518C16.9464 4.17586 17.1233 4.13968 17.302 4.13869C17.4808 4.13771 17.658 4.17194 17.8235 4.23944C17.989 4.30694 18.1397 4.40638 18.2668 4.53209C18.3939 4.6578 18.495 4.80731 18.5643 4.97209C18.6336 5.13686 18.6698 5.31368 18.6708 5.49244C18.6717 5.67121 18.6375 5.84841 18.57 6.01394L17.885 7.69394"
          stroke="#332723"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.8291 12.7359L17.8851 7.69395C18.0214 7.35964 18.285 7.09318 18.6177 6.95319C18.9505 6.81319 19.3253 6.81113 19.6596 6.94745C19.9939 7.08377 20.2604 7.34731 20.4004 7.68009C20.5404 8.01288 20.5424 8.38764 20.4061 8.72195L19.3781 11.2429"
          stroke="#332723"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.836 15.0252L19.378 11.2432C19.5143 10.9088 19.7779 10.6422 20.1108 10.5021C20.4437 10.362 20.8185 10.3599 21.153 10.4962C21.4874 10.6325 21.754 10.8961 21.8941 11.229C22.0342 11.5619 22.0363 11.9368 21.9 12.2712L18.474 20.6712C18.2491 21.2231 17.9177 21.7252 17.4987 22.149C17.0797 22.5728 16.5814 22.9099 16.0321 23.141C15.4828 23.3722 14.8934 23.4928 14.2974 23.4961C13.7015 23.4994 13.1108 23.3852 12.559 23.1602L9.75898 22.0182C9.05073 21.7299 8.42661 21.2677 7.94436 20.6743C7.46211 20.0809 7.1373 19.3754 6.99998 18.6232C6.48198 15.7542 6.21698 11.2692 6.21698 11.2692C6.15925 11.0113 6.19726 10.7412 6.32394 10.5093C6.45061 10.2773 6.65731 10.0994 6.90549 10.0086C7.15368 9.91778 7.42641 9.92032 7.67286 10.0157C7.91931 10.1112 8.12265 10.2929 8.24498 10.5272L9.41498 14.0422L12.841 5.64219C12.9085 5.47666 13.0079 5.32605 13.1336 5.19895C13.2593 5.07185 13.4088 4.97075 13.5736 4.90143C13.7384 4.83211 13.9152 4.79593 14.094 4.79494C14.2727 4.79396 14.45 4.82819 14.6155 4.89569C14.781 4.96319 14.9316 5.06263 15.0587 5.18834C15.1858 5.31405 15.2869 5.46356 15.3562 5.62834C15.4256 5.79311 15.4617 5.96993 15.4627 6.14869C15.4637 6.32746 15.4295 6.50466 15.362 6.67019L13.306 11.7122"
          stroke="#332723"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.48209 16.575C3.89078 16.2342 3.38416 15.7641 3.00009 15.2L1.29109 12.7C0.860894 12.0692 0.596539 11.3402 0.522435 10.5803C0.448331 9.82035 0.566865 9.054 0.867094 8.35198C2.01609 5.67298 4.26709 1.78398 4.26709 1.78398C4.36173 1.53778 4.5425 1.33428 4.77585 1.2113C5.00919 1.08832 5.27923 1.05421 5.53583 1.1153C5.79242 1.1764 6.01812 1.32854 6.171 1.54348C6.32389 1.75842 6.39357 2.02155 6.36709 2.28398L5.40709 5.86098L12.9001 0.737982C13.1915 0.544483 13.5462 0.47059 13.8906 0.531636C14.235 0.592682 14.5426 0.78398 14.7498 1.06584C14.9569 1.3477 15.0475 1.69847 15.0029 2.04539C14.9583 2.3923 14.7818 2.70872 14.5101 2.92898"
          stroke="#332723"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19 0.5V1.5"
          stroke="#332723"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M23 0.5L21.5 2"
          stroke="#332723"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22.5 4.5H23.5"
          stroke="#332723"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_231_3937">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default LikeIcon;
