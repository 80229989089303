import { _ } from "@/pages/_app";
import { dot } from "@/utils/stringUtils";

interface IAnyTypesWidget {
  types?: string[];
  title?: string;
}

const AnyTypesWidget = (props: IAnyTypesWidget) => {
  const types = props.types;
  if (!types || types.length === 0 || types[0] === "") {
    return <></>;
  }
  return (
    <div className="text-xs">
      <div className="font-bold">{_("Type")}</div>
      <div className="flex flex-wrap">
        {types.map((e, i) => (
          <div key={"any-types" + e + i}>
            <span className="mr-2"></span>
            {e} {i != types.length - 1 && <span className="ml-2">{dot}</span>}
          </div>
        ))}
      </div>
    </div>
  );
};

export default AnyTypesWidget;
