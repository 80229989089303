import TimeDistanceWidget from "@/components/training/timeDistanceWidget";
import { HorseActivity } from "@/openapi";
import { _ } from "@/pages/_app";
import AnyTypesWidget from "./anyTypesWidget";

interface IActivityLogTrackTraining {
  activity: HorseActivity;
}

const ActivityLogTrackTraining = (props: IActivityLogTrackTraining) => {
  const session = props.activity.trainingSession;

  if (!session) {
    return <div></div>;
  }

  const renderTrainingType = () => {
    if (!props.activity.title) {
      return <></>;
    }
    var types = props.activity.title.split("\n");

    return <AnyTypesWidget types={types} title={_("Training Type")} />;
  };
  return (
    <div>
      {renderTrainingType()}
      <div className="mt-4">
        <TimeDistanceWidget
          time={session.trainingSummary?.time}
          distance={session.trainingSummary?.distance}
        />
      </div>
    </div>
  );
};

export default ActivityLogTrackTraining;
