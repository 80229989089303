import { HorseActivity } from "@/openapi";
import GaitAnalysisComponent from "../tracking/gaitAnalysisComponent";

interface IActivityLogTrackTrainingBottom {
  activity: HorseActivity;
}
const ActivityLogTrackTrainingBottom = (
  props: IActivityLogTrackTrainingBottom
) => {
  return <GaitAnalysisComponent activity={props.activity} />;
};

export default ActivityLogTrackTrainingBottom;
