import { HorsedayIconProps } from "./IconProps";

const ShareIcon = (props: HorsedayIconProps) => {
  const stroke: string = props.color ?? "#332723";
  return (
    <svg
      width={props.width ?? "24"}
      height={props.height ?? "24"}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 13.499V0.499023"
        stroke="#332723"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.5 4.99902L12 0.499023L16.5 4.99902"
        stroke="#332723"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 8.5H19C19.2652 8.5 19.5196 8.60536 19.7071 8.79289C19.8946 8.98043 20 9.23478 20 9.5V22.5C20 22.7652 19.8946 23.0196 19.7071 23.2071C19.5196 23.3946 19.2652 23.5 19 23.5H5C4.73478 23.5 4.48043 23.3946 4.29289 23.2071C4.10536 23.0196 4 22.7652 4 22.5V9.5C4 9.23478 4.10536 8.98043 4.29289 8.79289C4.48043 8.60536 4.73478 8.5 5 8.5H9"
        stroke="#332723"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default ShareIcon;
