import { HorsedayIconProps } from "./IconProps";

const CommentIcon = (props: HorsedayIconProps) => {
  const stroke: string = props.color ?? "#332723";
  return (
    <svg
      width={props.width ?? "25"}
      height={props.height ?? "24"}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_231_3946)">
        <path
          d="M24 17C24 17.2652 23.8946 17.5196 23.7071 17.7071C23.5196 17.8946 23.2652 18 23 18H12L8 22V18H2C1.73478 18 1.48043 17.8946 1.29289 17.7071C1.10536 17.5196 1 17.2652 1 17V3C1 2.73478 1.10536 2.48043 1.29289 2.29289C1.48043 2.10536 1.73478 2 2 2H23C23.2652 2 23.5196 2.10536 23.7071 2.29289C23.8946 2.48043 24 2.73478 24 3V17Z"
          stroke="#332723"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6 12H19"
          stroke="#332723"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6 7H19"
          stroke="#332723"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_231_3946">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
export default CommentIcon;
